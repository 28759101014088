import axios from 'axios'
import {createSearchParams} from '../../utils/main'
import {Base_url} from '../../config'


//获取行政区域列表
function getDivsionList() {
    return new Promise(resolve => {
        axios({
            method: 'get',
            url: Base_url + '/admin/getDivsionList',
        }).then((res) => {
            resolve(res.data)
        })
    })
}

function getAllProvince() {
    return new Promise(resolve => {
        axios({
            method: 'get',
            url: Base_url + '/admin/getAllProvince',
        }).then((res) => {
            resolve(res.data)
        })
    })
}

//根据上一级获取下一级的列表
function getDivsionListByParentId(pId) {
    return new Promise(resolve => {
        axios({
            method: 'get',
            url: Base_url + '/admin/getDivsionListByParentId',
            params: {
                pId: pId
            }
        }).then((res) => {
            resolve(res.data)
        })
    })
}

function getChinaDivisionByName(name) {
    return new Promise(resolve => {
        axios({
            method: 'get',
            url: Base_url + '/admin/getChinaDivisionByName',
            params: {
                name: name
            }
        }).then((res) => {
            resolve(res.data)
        })
    })
}




//获取全部大区列表
/*function getChinaRegionList(){
 return new Promise(resolve => {
 axios({
 method: 'get',
 url: Base_url+'/admin/getChinaRegionIdList',
 }).then((res)=>{
 resolve(res.data)
 })
 })
 }*/

//添加省
function addRoot(regionId,divisionName, divisionCode,englishName) {
    return new Promise(resolve => {
        axios({
            method: 'post',
            url: Base_url + '/admin/save_adminarea',
            params: {
                parentId: "0",
                divisionName: divisionName,
                divisionCode: divisionCode,
                regionId:regionId,
                englishName:englishName
            }
        }).then((res) => {
            resolve(res.data)
        })
    })
}

//添加市区县
function saveAdministrative(data) {
    return new Promise(resolve => {
        axios({
            method: 'post',
            url: Base_url + '/admin/save_adminarea',
            data: createSearchParams(data)
        }).then((res) => {
            resolve(res.data)
        })
    })
}

//编辑省市区县
function updateAdministrative(data) {
    return new Promise(resolve => {
        axios({
            method: 'post',
            url: Base_url + '/admin/save_adminarea',
            data: createSearchParams(data)
        }).then((res) => {
            resolve(res.data)
        })
    })
}

//删除省市区县
function deleteAdministrative(pId) {
    return new Promise(resolve => {
        axios({
            method: 'delete',
            url: Base_url + '/admin/deleteAminarea',
            params: {
                id: pId
            }
        }).then((res) => {
            resolve(res.data)
        })
    })
}

export {
    getDivsionList,
    getDivsionListByParentId,
    addRoot,
    updateAdministrative,
    deleteAdministrative,
    saveAdministrative,
    getAllProvince,
    getChinaDivisionByName
}
