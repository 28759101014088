<template>
  <el-dialog title="新增" :visible.sync="addVisible" :before-close="closeEvent" width="600px">
    <el-form ref="form" label-width="100px">
      <el-form-item size="small" label="上级名称:" required>
        <p v-text="parentName"></p>
      </el-form-item>
      <el-form-item size="small" label="区域名称:" required>
        <el-input v-model="divisionName" maxlength="9" style="width: 435px" show-word-limit></el-input>
      </el-form-item>
      <el-form-item size="small" label="英文名称:" required>
        <el-input v-model="englishName" maxlength="20" style="width: 435px" show-word-limit></el-input>
      </el-form-item>
      <el-form-item size="small" label="区域代码:" required>
        <el-input v-model="divisionCode" maxlength="6" style="width: 435px" show-word-limit></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="saveAdministrative">确 定</el-button>
        <el-button size="small" @click="closeEvent">取 消</el-button>
      </span>
  </el-dialog>
</template>
<script>
import {saveAdministrative} from '../../api/bbi/china_division'

export default {
    data() {
        return {
            divisionName: '',
            divisionCode: '',
            englishName: '',
            areaCode: '',
        }
    },
    props: {
        id: String,
        parentName: String,
        addVisible: Boolean,
    },
    watch: {
        addVisible(val) {
            if (!val) {
                this.divisionName = '',
                    this.divisionCode = ''
            }
        }
    },
    methods: {
        closeEvent() {
            this.$emit('closeInsert')
        },
        async saveAdministrative() {
            let data = {
                parentId: this.id,
                divisionName: this.divisionName,
                englishName: this.englishName,
                divisionCode: this.divisionCode,
            };
            let res = await saveAdministrative(data);
            if (res.code == 200) {
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: 'success'
                });
                this.$emit('addSuccess')
            } else {
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: 'error'
                });
            }
        }
    }
}
</script>

<style scoped>

</style>
