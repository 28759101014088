<template>
  <div>
    <div class="e-breadcrumb">国内城市地区</div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <div class="e-card-body" style="padding-top:35px">
          <el-button type="primary" size="small" @click="addRootEvent">添加地区</el-button>
          <div class="container" style="width:400px;margin-top:10px;padding:10px">
            <v-tree v-loading="treeLoading" :nodes="nodes">
              <template slot-scope="{node}">
                <a href="javascript:;" @click.stop="addEvent(node)" class="v-tree-plus"></a>
                <a href="javascript:;" @click.stop="editEvent(node)" class="v-tree-edit"></a>
                <a href="javascript:;" @click.stop="removeEvent(node)" class="v-tree-remove"></a>
              </template>
            </v-tree>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="添加" width="600px" :visible.sync="addRootVisible">
      <el-form ref="form" label-width="100px">
        <el-form-item label="区域名称:" required>
          <el-input size="small" v-model="divisionName" maxlength="9" style="width: 439px;" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="英文名称:" required>
          <el-input size="small" v-model="englishName" maxlength="20" style="width: 439px;" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="区域代码:" required>
          <el-input size="small" v-model="divisionCode" maxlength="6" style="width: 439px;" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="所属大区:" required>
          <el-select size="small" style="width: 439px;" v-model="regionId" placeholder="请选择大区">
            <el-option
                v-for="item in regionList"
                :key="item.id"
                :label="item.name"
                :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="addRootVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="addRoot">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 删除提示框 -->
    <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
      <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="delAdministrative">确 定</el-button>
        <el-button size="small" @click="delVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <Insert v-if="addVisible" :addVisible="addVisible" @closeInsert="closeInsert" @addSuccess="addSuccess" :id="id"
            :parentName="parentName" :showDQ="showDQ"/>
    <Edit
        v-if="editVisible"
        :editVisible="editVisible"
        @closeEdit="closeEdit"
        @editSuccess="editSuccess"
        :editData="editData"
        :showDQ="showDQ"
    />
  </div>
</template>

<script>
import Insert from './insert'
import Edit from './edit'
import vTree from '@/components/common/v-tree/tree'
import {dataFormat} from '../../utils/main'
import {getDivsionList, addRoot, deleteAdministrative} from '../../api/bbi/china_division'
import {getRegionList} from '../../api/bbi/region'

export default {
    data() {
        return {
            regionList: [],
            englishName: '',
            regionId: '',
            regionNameList: [],
            showDQ: false,
            treeLoading: false,
            divisionName: "",
            divisionCode: "",
            areaCode: "",
            chinaRegionItem: [],
            chinaRegionId: '',
            pId: '',
            list: {},
            nodes: [],
            options: {
                data: {
                    simpleData: {
                        idKey: "id",
                        pIdKey: "parentId",
                    },
                    key: {
                        name: "divisionName",
                        children: 'children'
                    }
                }
            },
            id: '',
            parentName: '',
            addRootVisible: false,
            addVisible: false,
            editVisible: false,
            delVisible: false,
            editData: {}
        }
    },
    created() {
        this.searchEvent();
        this.getAllRegion();
        /*this.getChinaRegionList();*/
    },
    methods: {
        async searchEvent() {
            this.treeLoading = true;
            let res = await getDivsionList();
            // divisionName
            res.forEach(item => {
                item.divisionName = item.divisionName
                //+ (item.englishName?`(${item.englishName})`:'')
            })
            let data = dataFormat(this.options, res)
            this.nodes = data.list
            this.list = data.map
            this.treeLoading = false
        },
        /*async getChinaRegionList() {
            let res = await getChinaRegionList();
            res.forEach(item=>{
                this.chinaRegionItem.push({
                    label: item.regionName,
                    value: item.id
                })
            })
        },*/
        async getAllRegion() {
            // let res = await getRegionList();
            let res = await this.$get('/admin/getDictDetailList/REGION_TYPE/' + Math.random());
            this.regionList = res
        },
        //添加顶级权限
        async addRoot() {
            let res = await addRoot(this.regionId, this.divisionName, this.divisionCode, this.englishName)
            if (res.code == 200) {
                this.searchEvent();
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: 'success'
                });
                this.addRootVisible = false
            } else {
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: 'error'
                });
            }
        },
        addRootEvent() {
            this.divisionName = ''
            this.divisionCode = ''
            this.regionId = ''
            this.addRootVisible = true
        },

        addEvent(node) {
            this.showDQ = false
            this.addVisible = true;
            this.id = node.id
            if (node.parentId == '0') {
                this.showDQ = true
            }
            this.parentName = node.divisionName
        },
        editEvent(node) {
            this.showDQ = false
            this.editData = {
                id: node.id,
                parentId: node.parentId,
                regionId: node.regionId,
                divisionName: node.divisionName,
                englishName: node.englishName,
                foreignDivisionId: node.foreignDivisionId,
                divisionCode: node.divisionCode,
                areaCode: node.areaCode,
                longitude: node.longitude,
                latitude: node.latitude,
                parentName: (this.list[node.parentId] ? this.list[node.parentId].divisionName : '无')
            };
            if (node.parentId == '0') {
                this.showDQ = true
            }
            this.editVisible = true;
        },
        removeEvent(node) {
            this.pId = node.id
            this.delVisible = true
        },
        async delAdministrative() {
            let res = await deleteAdministrative(this.pId)
            if (res.code == 200) {
                this.searchEvent();
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: 'success'
                });
                this.delVisible = false
            } else {
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: 'error'
                });
            }
        },
        addSuccess() {
            this.closeInsert();
            this.searchEvent();
        },

        closeInsert() {
            this.addVisible = false;
            this.showDQ = false
        },
        editSuccess() {
            this.closeEdit();
            this.searchEvent();
        },
        closeEdit() {
            this.editVisible = false;
            this.showDQ = false
        }
    },
    components: {
        vTree,
        Insert,
        Edit
    }
}
</script>

<style scoped>
  .e-table-wrapper tbody .el-button{
    padding: 3px 0px;
  }
</style>
