<template>
  <el-dialog title="编辑" :visible.sync="editVisible" width="600px" :before-close="closeEvent">
    <el-form ref="form" label-width="100px">
      <el-form-item size="small" label="上级名称:" required>
        <p v-text="editData.parentName"></p>
      </el-form-item>
      <el-form-item size="small" label="区域名称:" required>
        <el-input v-model="editData.divisionName" maxlength="9" style="width: 435px" show-word-limit></el-input>
      </el-form-item>
      <el-form-item size="small" label="英文名称:" required>
        <el-input v-model="editData.englishName" maxlength="20" style="width: 435px" show-word-limit></el-input>
      </el-form-item>
      <el-form-item size="small" label="区域代码:" required>
        <el-input v-model="editData.divisionCode" maxlength="6" style="width: 435px" show-word-limit></el-input>
      </el-form-item>
      <el-form-item size="small" label="所属大区:" required v-if="showDQ">
        <el-select style="width: 435px" v-model="editData.regionId" placeholder="请选择大区">
          <el-option
              v-for="item in regionList"
              :key="item.id"
              :label="item.name"
              :value="item.dictValue"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="updateAdministrative">确 定</el-button>
        <el-button size="small" @click="closeEvent">取 消</el-button>
      </span>
  </el-dialog>
</template>
<script>
import {updateAdministrative} from '../../api/bbi/china_division'
import {getRegionList} from '../../api/bbi/region.js'

export default {
    data() {
        return {
            show: false,
            regionList: [],
            regionNameList: [],
            countryList: [],
        }
    },

    props: {
        showDQ: Boolean,
        editData: Object,
        editVisible: Boolean
    },
    created() {
        this.getAllRegion()
        this.getCountry()
        console.log(this.editData)
    },
    methods: {
        async getAllRegion() {
            let res = await this.$get('/admin/getDictDetailList/REGION_TYPE/' + Math.random());
            this.regionList = res
        },
        async getCountry() {
            let res = await this.$get('/admin/getCountryList')
            this.countryList = res
        },

        closeEvent() {
            this.$emit('closeEdit')
        },
        // async getAllRegion(){
        //   console.log(this.editData.regionId)
        //     let res = await getRegionList();
        //     this.regionNameList = res
        // },
        async updateAdministrative() {
            let data = {
                id: this.editData.id,
                parentId: this.editData.parentId,
                divisionName: this.editData.divisionName,
                englishName: this.editData.englishName,
                divisionCode: this.editData.divisionCode,
                regionId: this.editData.regionId,
            };
            let res = await updateAdministrative(data);
            if (res.code == 200) {
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: 'success'
                });
                this.$emit('editSuccess')
            } else {
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: 'error'
                });
            }
        }
    }
}
</script>

<style scoped>

</style>
